<template>
  <v-layout v-resize="onResize" v-show="show">
    <v-app-bar elevation="2" class="pr-2">
      <v-app-bar-nav-icon
        data-testid="open-nav-bar-button"
        v-if="route.path !== '/' && route.path !== '/profile'"
        @click.stop="drawer = !drawer"
        color="primary"
        class="my-2 ml-n2 mr-2"
        min-width="48px"
        min-height="48px"
      ></v-app-bar-nav-icon>

      <div
        v-if="!isMobile"
        @click="router.push('/')"
        class="d-flex align-center"
      >
        <v-img
          class="mr-2"
          style="max-width: 150px; max-height: 80px"
          src="/img/znap-app-bar.png"
        ></v-img>
      </div>

      <v-divider
        v-if="isHomol && !isMobile"
        class="mx-4"
        vertical
        inset
      ></v-divider>

      <span
        v-if="isHomol"
        class="text-uppercase font-weight-bold primary--text"
        :class="!isMobile ? 'headline' : ''"
      >
        {{ isMobile ? "SB" : "Sandbox" }}
      </span>

      <v-spacer></v-spacer>

      <v-menu
        v-if="selectedModule"
        bottom
        offset-y
        style="z-index: 1000 !important"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="mr-2" color="primary">
            <v-icon color="primary" class="mx-2">{{
              selectedModule.icon
            }}</v-icon>
            <span>{{ selectedModule.label }}</span>
          </v-btn>
        </template>

        <v-list style="overflow-x: hidden">
          <v-list-item-group
            v-model="selectionMenu"
            active-class="primary--text text--accent-4"
          >
            <a
              v-for="clientModule in modules"
              :key="clientModule.label"
              class="text-decoration-none"
              :href="clientModule.link"
            >
              <v-layout align-center>
                <v-flex xs11>
                  <v-list-item
                    @click="moduleRedirection(clientModule)"
                    @click.prevent="
                      nextTick(() => {
                        selectionMenu = null;
                      })
                    "
                    :class="
                      route.path.split('/')[1] ===
                      clientModule.path.split('/')[1]
                        ? 'primary--text'
                        : ''
                    "
                  >
                    <v-list-item-icon>
                      <v-icon color="primary"> {{ clientModule.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ clientModule.label }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-flex>
                <v-flex xs1>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-layout justify-center>
                        <a
                          :href="clientModule.link"
                          target="_blank"
                          class="text-decoration-none"
                          v-on="on"
                        >
                          <v-icon
                            class="px-2"
                            color="primary"
                            style="margin-left: -8px !important"
                            small
                            >mdi-open-in-new</v-icon
                          >
                        </a>
                      </v-layout>
                    </template>
                    <span>Abrir em nova aba</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </a>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <UserInfoMenu
        v-if="windowSize.x > 1"
        bottom
        offset-y
        style="z-index: 5000"
      />

      <v-divider
        v-if="windowSize.x > 600"
        class="ml-6 mr-4"
        vertical
        inset
      ></v-divider>

      <a
        v-if="windowSize.x > 600"
        href="https://znap.com.br/"
        style="text-decoration: none"
      >
        <v-img
          src="/img/znap-app-bar.png"
          max-width="95"
          max-height="50"
        ></v-img>
      </a>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" temporary app width="500">
      <v-container fluid fill-height class="pa-0 ma-0">
        <v-layout column>
          <div class="d-flex align-center">
            <v-app-bar-nav-icon
              data-testid="close-nav-bar-button"
              @click.stop="drawer = !drawer"
              color="primary"
              class="my-2 ml-1"
              min-width="48px"
              min-height="48px"
            ></v-app-bar-nav-icon>

            <v-img
              @click="router.push('/')"
              class="mr-2 mt-2"
              max-width="170"
              max-height="80"
              src="/img/znap-app-bar.png"
            ></v-img>
          </div>

          <v-divider></v-divider>

          <v-list>
            <div v-for="item in parents" :key="item.transactionCode">
              <v-list-item
                @click="handleParentMenuClick(item)"
                :class="{
                  'v-list-item--active': item.menus
                    ? childIsSelected(item)
                    : parentIsSelected(item),
                }"
                data-testid="menu-list-item"
              >
                <v-list-item-icon>
                  <v-icon color="primary">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="dark--text">
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action v-if="!item.menus">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        @click.stop="openInNew(item)"
                        @click.middle="openInNewAndRedirect(item)"
                      >
                        <v-icon v-on="on" color="white" small>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Abrir em nova aba</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>

              <v-expand-transition>
                <v-list v-show="item.expand" class="ml-14">
                  <v-list-item
                    @click="navigate(menu)"
                    v-for="menu in item.menus"
                    :key="menu.transactionCode"
                    class="text-decoration-none"
                    active-class=""
                  >
                    <v-list-item-icon
                      v-if="
                        route.path ===
                        `/${currentModule}${menu.frontend_route_path}`
                      "
                      class="mx-0"
                    >
                      <v-icon
                        color="primary"
                        style="margin-left: -8px !important"
                      >
                        mdi-menu-right
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title
                        class="text-subtitle-2 font-weight-regular dark--text"
                        data-testid="menu-list-submenu-item"
                      >
                        {{ menu.label }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click.stop="openInNew(menu)"
                            @click.middle="openInNewAndRedirect(menu)"
                          >
                            <v-icon v-on="on" color="white" small>
                              mdi-open-in-new
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Abrir em nova aba</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-expand-transition>
            </div>
          </v-list>

          <v-spacer></v-spacer>

          <v-footer color="primary">
            <v-layout align-center justify-center>
              <p class="text-caption text-center white--text">
                v. {{ appVersion }}
              </p>
            </v-layout>
          </v-footer>
        </v-layout>
      </v-container>
    </v-navigation-drawer>
  </v-layout>
</template>

<script setup>
import {
  computed,
  getCurrentInstance,
  nextTick,
  onMounted,
  ref,
  watch,
} from "vue";

import { useVuetify, useRoute, useRouter, useHttp, useIp } from "@/composables";
import { useAuthStore } from "@/stores";

import configs from "@/configs";
import packageJsonProps from "../../package";
import UserInfoMenu from "./znap/molecules/NavBar/UserInfoMenu.vue";

const vuetify = useVuetify();
const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();
const http = useHttp();
const ips = useIp();

const windowSize = ref({ x: 0, y: 0 });
const modules = ref([]);
const selectionMenu = ref(null);
const isHomol = ref(false);
const show = ref(true);
const drawer = ref(false);
const parents = ref([]);
const appVersion = ref(packageJsonProps.version);

const isMobile = computed(() => vuetify.breakpoint.mobile);
const currentModule = computed(() => route.path.split("/")[1]);
const currentRoutePath = computed(() => route.path);

const selectedModule = computed(() => {
  if (!modules.value.length) return null;

  const current = modules.value.find((item) => {
    if (!item.path) return null;

    const path = item.path.replaceAll("/", "");
    return path === currentModule.value;
  });

  return current ? { icon: current.icon, label: current.label } : null;
});

async function listModules() {
  const pathName = window.location.pathname;
  const isApproval = ["/approval-proposal", "/approval-requisition"].some(
    (path) => path === pathName
  );

  if (isApproval) return;

  const payload = {
    module: "ZNAP_PORTAL",
  };

  const res = await http.post(ips.ipUser + "user/menu", payload);

  if (!res) return;

  modules.value = res.data;

  authStore.setModulesObject(modules.value);

  let url = null;
  if (window.location.href.indexOf(":80") == -1) {
    url = `https://${configs.url}.znaptech.com`;
  } else {
    url = "http://localhost:8080";
  }

  modules.value.forEach((module) => (module.link = url + module.path));
}

watch(
  () => authStore.user,
  (val) => {
    if (!val.id_user) return;

    listModules();
  },
  { immediate: true }
);

function setPayloadModule(moduleName) {
  if (moduleName.includes("security")) {
    return "ZNAP_SECURITY";
  }

  if (moduleName.includes("masterdata")) {
    return "ZNAP_MASTER_DATA";
  }

  if (moduleName.includes("proposals")) {
    return "ZNAP_PROPOSAL";
  }

  if (moduleName.includes("purchases")) {
    return "ZNAP_PURCHASE";
  }

  if (moduleName.includes("approvalflow")) {
    return "ZNAP_APPROVAL_FLOW";
  }

  if (moduleName.includes("stock")) {
    return "ZNAP_STOCK";
  }
}

async function listMenu() {
  parents.value = [];

  const payload = {
    module: setPayloadModule(currentModule.value),
  };

  const res = await http.post(ips.ipUser + "user/menu", payload);

  if (!res) return;

  const items = res.data.filter(
    (item) => item.transactionCode !== "LOGIN" && !item.hideMenu
  );

  const childs = items.filter((i) => !i.transactionCode.includes("MENU_GROUP"));

  const hasOffParentItems = items.filter(
    (i) => i.offParent && !i.transactionCode.includes("MENU_GROUP")
  );

  // console.log('items', items)
  // console.log('childs', childs)
  // console.log('hasOffParentItems', hasOffParentItems)

  if (items.length !== childs.length) {
    // menu agrupado
    parents.value = items
      .filter((i) => i.transactionCode.includes("MENU_GROUP"))
      .map((p) => {
        return {
          ...p,
          expand: false,
          menus: childs.filter(
            (c) => c.id_transaction_parent === p.id_transaction_parent
          ),
        };
      });
  } else {
    // menu não agrupado
    parents.value = items;
  }

  if (hasOffParentItems) {
    parents.value.unshift(...hasOffParentItems);
  }

  authStore.setTableObject(items);
}

watch(
  currentModule,
  (val) => {
    if (!val) return;
    listMenu();
  },
  { immediate: true }
);

function navigateMenu(menu) {
  router.push(menu.path);
}

function onResize() {
  windowSize.value = { x: window.innerWidth, y: window.innerHeight };
}

function navigate(menu) {
  if ("/" + route.path.split("/")[2] === menu.frontend_route_path) return;

  router.push({ name: menu.frontend_route_path.substr(1) });
  drawer.value = false;

  parents.value = parents.value.map((p) => {
    return {
      ...p,
      expand: false,
    };
  });
}

function openInNewAndRedirect(menu) {
  let url = null;

  if (window.location.href.includes(":80")) {
    url = "http://localhost:8080/";
  } else {
    url = `https://${configs.url}.znaptech.com/`;
  }

  window.open(
    `${url}${currentModule.value}${menu.frontend_route_path}`,
    "_blank"
  );
}

function openInNew(menu) {
  let url = null;

  if (window.location.href.includes(":80")) {
    url = "http://localhost:8080/";
  } else {
    url = `https://${configs.url}.znaptech.com/`;
  }

  window.open(
    `${url}${currentModule.value}${menu.frontend_route_path}`,
    "_blank"
  );
}

function moduleRedirection(clientModule) {
  route.path.split("/")[1] !== clientModule.path.split("/")[1]
    ? navigateMenu(clientModule)
    : false;
}

function childIsSelected(parent) {
  const routePath = route.path.split("/");

  if (routePath.length < 3) return false;

  const found = parent.menus.find(
    (c) => c.frontend_route_path === `/${routePath[2]}`
  );

  return !!found;
}

function parentIsSelected(parent) {
  return route.path === `/${currentModule.value}${parent.frontend_route_path}`;
}

function handleParentMenuClick(item) {
  if (item.menus) {
    // menu agrupado
    item.expand = !item.expand;
  } else {
    // menu não agrupado
    router.push(`/${currentModule.value}${item.frontend_route_path}`);
  }
}

const currentInstance = getCurrentInstance();
currentInstance?.proxy.$root.$on("hideAppBar", ($event) => {
  show.value = !$event;
});

onMounted(() => {
  if (configs.env === "homol") {
    isHomol.value = true;
  }
});

watch(
  currentRoutePath,
  (route) => {
    const mainViews = [
      "/security",
      "/masterdata",
      // "/proposals",
      "/purchases",
      "/approvalflow",
      // "/stock",
    ];

    if (mainViews.includes(route)) {
      drawer.value = true;
    }

    if (route === "/stock") {
      router.push({ name: "stock-event-calendar" });
    }

    if (route === "/proposals") {
      router.push({ name: "proposal-event-calendar" });
    }
  },
  { immediate: true }
);
</script>

<style>
a {
  text-decoration: none;
  color: gray !important;
}
</style>
